import { Injectable } from '@angular/core';

const START_INDEX_PAR_DEFAUT = 1;
const NOMBRE_COMPTES_PAR_PAGE_PAR_DEFAUT = 20;
const INDEX_DE_PAGE_PAR_DEFAUT = 0;
@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  startIndex: number = START_INDEX_PAR_DEFAUT;
  nombreComptesParPage:number = NOMBRE_COMPTES_PAR_PAGE_PAR_DEFAUT;
  indexDePage: number = INDEX_DE_PAGE_PAR_DEFAUT;

  constructor() { }

  obtenirStartIndex(): number {
    return this.startIndex;
  }

  obtenirNombreComptesParPage(): number {
    return this.nombreComptesParPage;
  }

  obtenirIndexDePage(): number {
    return this.indexDePage;
  }

  changerStartIndex(valeur: number) {
    this.startIndex = valeur;
  }

  changerNombreComptesParPage(valeur: number) {
    this.nombreComptesParPage = valeur;
  }

  changementIndexDePage(valeur:number) {
    this.indexDePage = valeur;
  }

  reinitialiserPagination() {
    this.startIndex = START_INDEX_PAR_DEFAUT;
    this.nombreComptesParPage = NOMBRE_COMPTES_PAR_PAGE_PAR_DEFAUT;
    this.indexDePage = INDEX_DE_PAGE_PAR_DEFAUT;
  }
}
