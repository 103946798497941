import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wk-error',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css'],
})
export class ErrorsComponent implements OnInit {
  messageErreur: string;
  codeErreur: string;

  constructor(private activatedRouter: ActivatedRoute) {
  }

  ngOnInit() {
    this.codeErreur = this.activatedRouter.snapshot.paramMap.get('id');
    if (this.codeErreur === "400") {
      this.messageErreur = this.codeErreur+" - Mauvaise requête: La requête HTTP n'a pas pu être comprise par le serveur en raison d'une syntaxe erronée.";
    }else if (this.codeErreur === "401") {
      this.messageErreur = this.codeErreur+" - Non autorisé: La requête nécessite une identification de l'utilisateur.";
    }else if (this.codeErreur === "403") {
      this.messageErreur = this.codeErreur+" - Interdit: Le serveur HTTP a compris la requête, mais refuse de la traiter.";
    }else if (this.codeErreur === "404") {
      this.messageErreur = this.codeErreur+" - Non trouvé: Le serveur n'a rien trouvé qui corresponde à l'adresse (URI) demandée ( non trouvé ).";
    }else if (this.codeErreur === "500") {
      this.messageErreur = this.codeErreur+" - Erreur interne du serveur: Le serveur HTTP a rencontré une condition inattendue qui l'a empêché de traiter la requête.";
    }else if (this.codeErreur === "-1") {
      this.messageErreur = " - Veuillez vérifier votre connexion internet et réessayer";
    }else{
      this.messageErreur = "Il y a eu un problème, si le problème persiste, contactez votre administrateur de réseau.";
    }
  }
}
