<div class="formulaire">
    <div [ngClass]="{ 'messageErreur': erreurs.length != 0 }">
        <li *ngFor="let erreur of erreurs">
            {{erreur}}
        </li>
    </div>

    <div [ngClass]="{ 'messageSucces': messageSuccesChangement.length != 0 }">
        <p>{{messageSuccesChangement}}</p>
    </div>
    <div class="container">
        <mat-expansion-panel expanded>
            <br>
            <div class="row">
                <div class="col-sm-2">
                    <p>
                        <b>Identifiant</b>
                    </p>
                </div>
                <div class="col-sm-4">
                    <input class="form-control form-control-sm" value="{{identifiant}}" disabled>
                </div>

                <div class="col-sm-2">
                    <p>
                        <b>Courriel</b>
                    </p>
                </div>
                <div class="col-sm-4">
                    <input class="form-control form-control-sm" value="{{courriel}}" #courrielHTML type="email" disabled>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-2">
                    <p>
                        <b>Prénom</b>
                    </p>
                </div>
                <div class="col-sm-4">
                    <input class="form-control form-control-sm" value="{{prenom}}" #prenomHTML disabled>
                </div>

                <div class="col-sm-2">
                    <p>
                        <b>Nom</b>
                    </p>
                </div>
                <div class="col-sm-4">
                    <input class="form-control form-control-sm" value="{{nom}}" #nomHTML disabled>
                </div>
            </div>
            <br>
        </mat-expansion-panel>
    </div>
    <div class="container">
        <button id="retour" mat-raised-button (click)="retournerAuTableauSansModification()">Retour</button>
        <button id="init-pin" mat-raised-button (click)="reinitialiserPin()" *ngIf="this.getPrivilegePin()" >Réinitialiser le NIP</button>
    </div>
    <group-role-component (rechargerPageAvecMessageErreur)='rechargerPageAvecMessage($event)'
        (valueChange)='ngOnInit()'></group-role-component>
    <div class="container"><button id="retour" mat-raised-button (click)="retournerAuTableauSansModification()">Retour</button></div>
</div>