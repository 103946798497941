import { Component, OnInit, ViewChild} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientSx5 } from '../services/http.client.sx5';
import { GroupRoleComponent } from '../group-roles/group-role-component';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MessageService } from '../services/message.service';
import { TraitementErreurService } from '../services/traitement-erreurs/traitement-erreur.service';

const PREMIER_COURRIEL_DEFAUT = 0;

@Component({
    selector: 'formulaireAffichage',
    styleUrls: ['formulaireAffichage.css'],
    templateUrl: 'formulaireAffichage.html',
    providers: [GroupRoleComponent]
  })

  export class FormulaireAffichage implements OnInit {

    identifiant: string;
    prenom: string = "";
    nom: string = "";
    courriel: string = "";
    privileges: any[] = [];
    erreurs: string[] = [];
    messageSuccesChangement: string = "";

    @ViewChild(GroupRoleComponent, {static: true}) groupRoleComponent: GroupRoleComponent;

    constructor(private activatedRouter: ActivatedRoute, private router: Router,private clientHTTPSX5: HttpClientSx5,private message: MessageService, public traitementErreurs: TraitementErreurService) {
      this.activatedRouter.params.subscribe(params => this.identifiant = params['identifiant']);
    }

    changementDePage(event: PageEvent) {
      this.actualiserMessageAAfficher();
    }

    rechargerPageAvecMessage(messageUser:string[]){
      this.traitementErreurs.traitementErreur(messageUser[1]);
      this.identifiant = messageUser[2];
      this.ngOnInit();
    }

    ngOnInit(): Promise<any> {
      this.obtenirInformationsUtilisateur();
      this.obtenirListeSystemes();
      return;
    }

    retournerAuTableauSansModification(): Promise<boolean> {
      return this.router.navigate(['']);
    }

    private obtenirInformationsUtilisateur() {
      this.clientHTTPSX5.obtenirUtilisateurParIdentifiantGETHttp(this.identifiant)
        .subscribe(
          resultat => {
            this.enregistrerInformationsUtilisateur(resultat);
          },
          (erreur) => this.traitementErreurFormulaire(erreur)
        );
    }

    private obtenirListeSystemes() {
      this.clientHTTPSX5.obtenirListeSystemesGETHttp()
        .subscribe(
          resultat => this.remplirListesSystemeRoleComposant(resultat),
          (erreur) => this.traitementErreurFormulaire(erreur)
        );
    }

    remplirListesSystemeRoleComposant(resultat){
      this.groupRoleComponent.remplirListesSystemesRolesComposants(resultat);
    }

    enregistrerInformationsUtilisateur(result) {
      this.prenom = result.name.givenName;
      this.nom = result.name.familyName;
      this.courriel = result.emails[PREMIER_COURRIEL_DEFAUT].value;
      this.privileges = result.entitlements;
      this.groupRoleComponent.remplirDonnees(result);
    }

    traitementErreurFormulaire(error) {
      if(this.erreurs.length != 0){
        this.erreurs = [];
      }   
      if (error.message!=undefined && error.message.match(/\|/g)) {
        this.erreurs = error.split("|");
      }    
      else {
        this.erreurs.push(error);
      }
    }

    getPrivilegePin() {
      if(this.privileges) {
        for (const privilege of this.privileges) {
          if (privilege.type === "pin") {
            return privilege;
          }
        }
      }
      return null;
    }
  
    removePrivilegePin() {
      var ar = []
      for (const privilege of this.privileges) {
        if (privilege.type !== "pin") {
          ar.push(privilege);
        }
      }
      this.privileges = ar;
    }

    reinitialiserPin() {
      if(this.getPrivilegePin()) {
        this.actualiserMessageAAfficher();
        const privilege = this.getPrivilegePin();
        this.clientHTTPSX5.reinitialiserPinPOSTHttp(this.identifiant, privilege.value).subscribe(
          resultat => {
            this.removePrivilegePin();
            this.message.mettreUnMessage('Le NIP de l\'utilisateur ' + this.identifiant + ' a ete supprime.');
          },
          (erreur: string) => this.traitementErreurs.traitementErreur(erreur)
        );
      }
    }

    private actualiserMessageAAfficher() {
      if (this.message.message, length != 0) {
        this.messageSuccesChangement = this.message.message;
        this.message.viderMessage();
      }
      else {
        this.messageSuccesChangement = this.message.message;
      }
    }
  }