export const environment = {
  production: true,
  intervalleVerificationSession: 1000,
  keycloakRootUrl: 'https://gia.sx5.rtss.qc.ca',
  keycloakrealm: 'msss',
  keycloakclientid: 'sx5habilitationuiprod',
  keycloakclientsecret: '',
  keycloakcheckLoginIframe: true,
  sx5ServiceUrl: 'https://habilitationservices.sx5.rtss.qc.ca',
  angularProtectUrl: 'https://habilitationui.sx5.rtss.qc.ca',
  theme: 'msss',
  logLevel: 'ERROR'
};
