export class UserResource {
    schemas: string[];
    id: string;
    meta: Meta;
    userName:string;
    name: Name;
    emails: Email[];
    groups: Groups[];
    entitlements:Entitlements[];
    roles: Roles[];
    ["urn:sx5:scim:api:messages:2.0:Habilitations"]:Habilitations;
}
export class Habilitations {
    habilitations?: (HabilitationsEntity)[] | null;
  }
export class HabilitationsEntity {
    nomRole: string;
    descriptionRole: string;
    nomComposant: string;
    cleUniqueSysteme: string;
    nomSysteme: string;
    assigne: boolean;
    dateEcheance: string;
    descriptionComposant: string;
}
export class Entitlements{
    value:string;
    display:string;
    type:string;
    primary:string;
}
export class Schemas{
    schemas: string;
}
export class Meta{
    resourceType: string;
    created: string;
    lastModified: string;
    location: string;
    version: string;
}
export class Name{
    formatted: string;
    familyName: string;
    givenName: string;
    middleName: string;
    honorificPrefix: string;
    honorificSuffix: string;
}
export class Email{
    value: string;
    display: string;
    type: string;
    primary: string;
}
export class Roles{
    value: string;
    display: string;
    type: string;
    primary: string;
}
export class Groups{
    value: string;
    display: string;
    type: string;
    primary: string;
}