import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await keycloak.init({
            config: {
                url: environment.keycloakRootUrl + '/auth',
                realm: environment.keycloakrealm,
                clientId: environment.keycloakclientid
            },
            initOptions: {
                onLoad: 'check-sso', 
                checkLoginIframe: environment.keycloakcheckLoginIframe
            }
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}