import { Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { FileDatabase, FileNode, ArbreHabilitationComponent } from '../role-arbre/arbre-habilitation.component';
import { HttpClientSx5 } from '../services/http.client.sx5';
import { UserResource, Habilitations, HabilitationsEntity } from './userResource';
import { TraitementErreurService } from '../services/traitement-erreurs/traitement-erreur.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import * as moment from 'moment';

const VIDE = 0;
const MESSAGE_VALIDATION_DATE = "Veuillez ajouter un format valide AAAA-MM-JJ";
const MESSAGE_VALIDATION_DATE_SUPERIEURE = "Veuillez ajouter une date supérieure à celle d'aujourd'hui";
const MESSAGE_ERREUR_INCONNU = "Erreur inconnu";

@Component({
  selector: 'group-role-component',
  templateUrl: 'group-role-component.html',
  styleUrls: ['group-role-component.css'],
})
export class GroupRoleComponent implements OnInit {
  panelOpenState: boolean = false;

  selected: string;
  role: string;
  composant: string;
  displayedColumnsRoles = ['composant', 'role'];
  systemeComposant: string[] = [];
  listeSystemes: string[] = [];
  listeDeGroupes: Groups[] = [];
  listeDeRoles: Groups[] = [];
  listeDeRolesSysteme: RoleSysteme[] = [];
  listeDeRolesSystemeFiltre: RoleSysteme[] = [];
  listeDeRolesFiltre: string[] = [];
  expandedGroup: boolean;
  expandedRoles: boolean;
  expandedRolesSysteme: boolean;
  listeDeSystemes: Systeme[] = [];
  listeDesRoles: Roles[] = [];
  listeDesComposants: Composants[] = [];
  userName:string = "";
  userResource: UserResource;  
  fileNodeList: FileNode[] = [];
  messageSucces: string = "";
  groupeDeControle: UntypedFormGroup;
  tousComposants:string = "TousComposants";
  voirLesHabilitations: boolean = true;
  dateMin = new Date();
  formatDateSaisie: string = "";
  activeTab: string = 'bb';

  @ViewChild(ArbreHabilitationComponent) arbreHabilitation : ArbreHabilitationComponent;

  @Output() valueChange = new EventEmitter();
  @Output() rechargerPageAvecMessageErreur = new EventEmitter();
  @Output() effacerRole = new EventEmitter();

  constructor(private clientHTTPSX5: HttpClientSx5,
              public traitementErreurs: TraitementErreurService,
              private routeur: Router){    
  }

  ngOnInit(): Promise<any>{
    this.traitementErreurs.viderErreurs();
    this.groupeDeControle = new UntypedFormGroup({
      selected    : new UntypedFormControl('', [Validators.required]),
      composant   : new UntypedFormControl('', []),
      role        : new UntypedFormControl('', [Validators.required]),
      dateEcheance: new UntypedFormControl('', [])
    });
    return;
  }

  getErrorMessage(pickerInput: string): string {
    if (!pickerInput || pickerInput === '' ) {
      this.groupeDeControle.controls.dateEcheance.setErrors(null);
      this.groupeDeControle.controls.dateEcheance.markAsTouched();
      return '';//'Please choose a date.';
    }
    return this.isMyDateFormat(pickerInput);
  }

  /**
   * An example of custom validator on a date string.
   * Valid date is a string, which is:
   * 1, in the form of YYYY-MM-DD
   * 2, later than today
   * 3, not an invalid value like 2018-20-81
   * @param date a date string
   */
  isMyDateFormat(date: string): string {
    if (date.length !== 10) {
      return MESSAGE_VALIDATION_DATE;//'Invalid input: Please input a string in the form of YYYY-MM-DD';
    } else {
      const da = date.split('-');
      if (da.length !== 3 || da[0].length !== 4 || da[1].length !== 2 || da[2].length !== 2) {
        return MESSAGE_VALIDATION_DATE;//'Invalid input: Please input a string in the form of YYYY-MM-DD';
      } else if (moment(date).isValid()) {
        return MESSAGE_VALIDATION_DATE_SUPERIEURE;//'Invalid date: Please input a date no later than today';
      } else if (!moment(date).isValid()) {
        return MESSAGE_VALIDATION_DATE;//'Invalid date: Please input a date with a valid month and date.';
      }
    }
    return MESSAGE_ERREUR_INCONNU;//'Unknown error.';
  }

  remplirDonnees(result){
    this.remplirUserResource(result);
    this.userName = result.userName;
    this.remplirListeDeGroupes(result);
    this.remplirListeDeRoles(result);
    this.remplirListeDeComposantsEtRolesDeSysteme(result);
    this.creerStructureDonneesPourLArbre();
    this.remplirArbre();
  }

  remplirUserResource(result:any){
    this.userResource = new UserResource();
    this.userResource.schemas = result.schemas;
    this.userResource.id = result.id;
    this.userResource.meta = result.meta;
    this.userResource.userName = result.userName;
    this.userResource.emails = result.emails;
    this.userResource.groups = (result.groups!=null) ? result.groups : [];
    this.userResource.entitlements = (result.entitlements!=null) ? result.entitlements : [];
    this.userResource.roles = result.roles;
    this.userResource.name= result.name;
    this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"] = result["urn:sx5:scim:api:messages:2.0:Habilitations"];
  }
  
  remplirListesSystemesRolesComposants(result){
    this.listeDeSystemes.length = VIDE;
    if(result!=undefined){
      var roless:Roles[]=[];
      var composants:Composants[]=[];

      for(var i:number=0; i<result.length; i++){//Systèmes
        if(result[i].roles!=undefined){
          roless=[];
          for(var j:number=0; j<result[i].roles.length; j++){//Rôles
            if(result[i].roles[j].composants!=undefined){
              composants=[];
              for(var k:number=0; k<result[i].roles[j].composants.length; k++){//Composants
                composants.push({
                  nomComposant: result[i].roles[j].composants[k].nomComposant,
                  descriptionComposant: result[i].roles[j].composants[k].descriptionComposant
                });
              }
            }
            roless.push({
              nomRole: result[i].roles[j].nomRole,
              descriptionRole: result[i].roles[j].descriptionRole,
              composants: composants
            });
          }
        }        
        this.listeDeSystemes.push({
          nomSysteme: result[i].nomSysteme,
          roles: roless
        });
      }
    }
    this.listeDeSystemes.sort((a, b) => a.nomSysteme.localeCompare(b.nomSysteme));

    if(this.listeDeSystemes.length>0){
      this.voirLesHabilitations = true;
      if(this.listeDeSystemes.length==1){
        this.groupeDeControle.controls.selected.setValue(this.listeDeSystemes[0].nomSysteme);
      }
      this.remplirListeDeComposantsEtRolesDeSystemeFiltre();
    }else{
      this.voirLesHabilitations = false;
    }
  }

  compareFn(c1: any, c2:any): boolean {
    return c1==c2;
  }

  private creerStructureDonneesPourLArbre(){
    this.fileNodeList.length = VIDE;
    for(var i:number=0; i<this.listeDeRolesSysteme.length; i++){      
      this.insererSysteme(this.listeDeRolesSysteme[i].systeme);
      this.insererRole(this.listeDeRolesSysteme[i].systeme, this.listeDeRolesSysteme[i].role, this.listeDeRolesSysteme[i].assigne, this.listeDeRolesSysteme[i].dateEcheance);
      this.insererComposant(this.listeDeRolesSysteme[i].systeme, this.listeDeRolesSysteme[i].role, this.listeDeRolesSysteme[i].composant, this.listeDeRolesSysteme[i].assigne);
    }
    this.fileNodeList.sort((a, b) => a.filename.localeCompare(b.filename));
  }

  private remplirArbre() {
    var fileDatabase = new FileDatabase();
    fileDatabase.dataChange.next(this.fileNodeList);
    if(this.arbreHabilitation!=undefined){
      this.arbreHabilitation.fileNodeList = this.fileNodeList;
      this.arbreHabilitation.configuration(fileDatabase);
    }    
  }

  remplirListeDeGroupes(result): Array<Groups> {
    this.listeDeGroupes.length = VIDE;
    if(result.groups!=undefined){
      for (let i = 0; i < result.groups.length; i++) {
        this.listeDeGroupes.push({
          value: result.groups[i].value,
          assigne: false
        });
      }
    }
    this.listeDeGroupes.length>0 ? this.expandedGroup=true : this.expandedGroup=false;
    return this.listeDeGroupes;
  }

  remplirListeDeRoles(result): Array<Groups> {
    this.listeDeRoles.length = VIDE;
    if(result.roles!=undefined){
      var listRoleFournisseurs = this.listRoleFournisseurServices(result);
      for (let i = 0; i < result.roles.length; i++) {
        this.listeDeRoles.push({
          value: result.roles[i].value,
          assigne: listRoleFournisseurs[result.roles[i].value]
        });
      }
    }   
    this.listeDeRoles.length>0 ? this.expandedRoles=true : this.expandedRoles=false;
    return this.listeDeRoles;
  }

  private listRoleFournisseurServices(result): Array<boolean> {
    var list: boolean[] = [];
    if(result["urn:sx5:scim:api:messages:2.0:RoleFournisseurServices"] != undefined){
      var roleFournisseurServices = result["urn:sx5:scim:api:messages:2.0:RoleFournisseurServices"].roleFournisseurs;
      for (let i = 0; i < roleFournisseurServices.length; i++) {
        list[roleFournisseurServices[i].nomRole] = roleFournisseurServices[i].assigne;
      }
    }
    return list;
  }

  remplirListeDeComposantsEtRolesDeSysteme(result): Array<RoleSysteme> {
    this.listeDeRolesSysteme.length = VIDE;
    this.listeSystemes.length = VIDE;
    if(result["urn:sx5:scim:api:messages:2.0:Habilitations"]!=undefined){
      for (let i = 0; i < result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations.length; i++) {
        this.remplirListeSystemes(result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].nomSysteme);
        this.listeDeRolesSysteme.push({
          composant:  result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].descriptionComposant!=undefined ? result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].descriptionComposant : "",
          role:       result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].descriptionRole,
          systeme:    result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].nomSysteme!=undefined ? result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].nomSysteme : "",
          assigne:    result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].assigne,
          dateEcheance: result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].dateEcheance!=undefined ? result["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].dateEcheance : "",
        });
      }
    }
    this.listeDeRolesSysteme.length>0 ? this.expandedRolesSysteme=true : this.expandedRolesSysteme=false;
    this.listeSystemes = this.effacerValeursRepetesAvecFiltre(this.listeSystemes);
    return this.listeDeRolesSysteme;
  }

  insererSysteme(systeme:string){
    if(!this.existeSysteme(systeme)){
      var nouveauSysteme:FileNode = new FileNode();
      nouveauSysteme.filename = systeme;
      nouveauSysteme.children = [];
      nouveauSysteme.type = null;      
      this.fileNodeList.push(nouveauSysteme);
    }
  }
  existeSysteme(systeme:string): boolean{
    if(this.fileNodeList!=undefined && this.fileNodeList.length>0){
      for(var i:number=0; i<this.fileNodeList.length; i++){
        if(this.fileNodeList[i].filename==systeme){
          return true;
        }
      }
    }
    return false;
  }
  insererRole(systeme:string, role:string, primary:boolean, dateEcheance:string): boolean{
    if(this.fileNodeList!=undefined && this.fileNodeList.length>0){
      for(var i:number=0; i<this.fileNodeList.length; i++){
        if(this.fileNodeList[i].filename==systeme){
          if(this.fileNodeList[i].children!=undefined && this.fileNodeList[i].children.length>0){
            for(var j:number=0; j<this.fileNodeList[i].children.length; j++){              
              if(this.fileNodeList[i].children[j].filename==role){
                return true;
              }
            }
          }        
          //Ajouter ici
          var nouveauRole:FileNode = new FileNode();
          nouveauRole.filename = role;
          nouveauRole.children = [];
          nouveauRole.type = "role";
          nouveauRole.parentName = this.fileNodeList[i].filename;
          nouveauRole.primary    = primary;
          nouveauRole.dateEcheance = dateEcheance;
          this.fileNodeList[i].children.push(nouveauRole); 
          this.fileNodeList[i].children.sort((a, b) => a.filename.localeCompare(b.filename));
        }
      }
    }
  }
  insererComposant(systeme:string, role:string, composant:string, primary:boolean){
    if(this.fileNodeList!=undefined && this.fileNodeList.length>0){
      for(var i:number=0; i<this.fileNodeList.length; i++){
        if(this.fileNodeList[i].filename==systeme){
          if(this.fileNodeList[i].children!=undefined && this.fileNodeList[i].children.length>0){
            for(var j:number=0; j<this.fileNodeList[i].children.length; j++){
              if(this.fileNodeList[i].children[j].filename==role){
                if(this.fileNodeList[i].children[j].children!=undefined && this.fileNodeList[i].children[j].children.length>0){
                  for(var k:number=0; k<this.fileNodeList[i].children[j].children.length; k++){
                    if(this.fileNodeList[i].children[j].children[k].filename==composant){
                      return true;
                    }
                  }
                }                
                //Ajouter ici
                var nouveauComposant:FileNode = new FileNode();
                nouveauComposant.filename = composant;
                nouveauComposant.parentName = this.fileNodeList[i].children[j].filename;
                nouveauComposant.children = null;
                nouveauComposant.type = "composant";
                nouveauComposant.primary = primary;
                this.fileNodeList[i].children[j].children.push(nouveauComposant);
                this.fileNodeList[i].children[j].children.sort((a, b) => a.filename.localeCompare(b.filename));
              }
            }
          }
        }
      }
    }
  }

  private remplirListeSystemes(systeme: string){
    if(systeme!=undefined){
      this.listeSystemes.push(systeme);
    }
  }

  private effacerValeursRepetesAvecFiltre(arr): string[]{
    let unique_array = arr.filter(function(elem, index, self) {
        return index == self.indexOf(elem);
    });
    return unique_array
}

  remplirListeDeComposantsEtRolesDeSystemeFiltre() {
    this.listeDesRoles.length = VIDE;
    this.listeDesComposants.length = VIDE;
    if(this.listeDeSystemes!=undefined){
      for (let i = 0; i < this.listeDeSystemes.length; i++) {
        if(this.listeDeSystemes[i].nomSysteme===this.groupeDeControle.controls.selected.value){
          for(let j=0; j<this.listeDeSystemes[i].roles.length; j++){
            this.listeDesRoles.push({
              nomRole: this.listeDeSystemes[i].roles[j].nomRole,
              descriptionRole: this.listeDeSystemes[i].roles[j].descriptionRole,
              composants: this.listeDeSystemes[i].roles[j].composants
            });
          }
        }
      }
    }

    this.listeDesRoles.sort((a, b) => a.descriptionRole.localeCompare(b.descriptionRole));

    this.groupeDeControle.controls.role.setValue("");
    this.groupeDeControle.controls.composant.setValue("");
  }

  remplirListeDeComposantsXRoleFiltre() {
    this.listeDesComposants.length = VIDE;
    if(this.listeDeSystemes!=undefined){
      for (let i = 0; i < this.listeDeSystemes.length; i++) {
        if(this.listeDeSystemes[i].nomSysteme===this.groupeDeControle.controls.selected.value){
          for(let j=0; j<this.listeDeSystemes[i].roles.length; j++){
            if(this.listeDeSystemes[i].roles[j].descriptionRole==this.groupeDeControle.controls.role.value){
              for(let k=0; k<this.listeDeSystemes[i].roles[j].composants.length; k++){
                this.listeDesComposants.push({
                  nomComposant: this.listeDeSystemes[i].roles[j].composants[k].nomComposant,
                  descriptionComposant: this.listeDeSystemes[i].roles[j].composants[k].descriptionComposant
                });
              }
            }            
          }
        }
      }
    }
    this.listeDesRoles;

    this.listeDesComposants.sort((a, b) => a.descriptionComposant.localeCompare(b.descriptionComposant));

    this.groupeDeControle.controls.composant.setValue("");
  }

  ajouterHabilitation(dateEcheance){
    let habilitation:HabilitationsEntity = new HabilitationsEntity();
    habilitation.descriptionRole = this.groupeDeControle.controls.role.value;
    habilitation.nomRole = this.getNomRole(this.groupeDeControle.controls.role.value);
    if(this.groupeDeControle.controls.composant.value=="" || this.groupeDeControle.controls.composant.value=="TousComposants"){
      habilitation.nomSysteme = this.groupeDeControle.controls.selected.value;
    }else{
      habilitation.nomSysteme = this.groupeDeControle.controls.selected.value;
      habilitation.nomComposant = this.getNomComposant(this.groupeDeControle.controls.composant.value);
    }
    if(dateEcheance!=undefined && dateEcheance!=""){
      habilitation.dateEcheance = this.getDateEcheance(dateEcheance);
    }
    
    habilitation.assigne = null;

    this.userResource.userName = this.userName;
    if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"]==undefined){
      this.ajouterNouvelleHabilitation();
      this.ajouterSchema();
    }
    this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations.push(habilitation);
    this.viderChampsDAjout();
    this.reinitialiserValidationsObligatoires();

    this.modifierHabilitation(this.userResource, true);
  }

  private isValidDate(dateString) {
    var regEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    var resultat = dateString.match(regEx);
    return resultat;
  }

  getDateEcheance(dateEche){
    var dateReponse = "";
    if(dateEche!=null){
      if(this.isValidDate(dateEche)!=null || dateEche==""){
        var date = new Date();
        date.setHours(0,0,0,0);
        var parts =dateEche.split('-');
        // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
        // January - 0, February - 1, etc.
        var date2 = new Date(parts[0], parts[1] - 1, parts[2]);
        date2.setHours(0,0,0,0);
        if((date2 >= date) || (dateEche=="")){
          dateReponse = dateEche;
        }       
      }
    }
    return dateReponse; 
  }

  private ajouterNouvelleHabilitation(){
    var habilitations:Habilitations = new Habilitations();
      var habilitationEntity:HabilitationsEntity[] = [];
      habilitations.habilitations = habilitationEntity;
      this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"] = habilitations;
  }

  private ajouterSchema(){
    this.userResource.schemas.push("urn:sx5:scim:api:messages:2.0:Habilitations");
  }

  private reinitialiserValidationsObligatoires() {
    this.enleverControlsObligatoires();
    this.ajouterControlsObligatoires();
  }

  viderChampsDAjout(){
    this.groupeDeControle.controls.selected.setValue("");
    this.groupeDeControle.controls.role.setValue("");
    this.groupeDeControle.controls.composant.setValue("");
    this.groupeDeControle.controls.dateEcheance.setValue("");
    this.listeDesComposants.length = VIDE;
    this.listeDesRoles.length = VIDE;

  }

  private ajouterControlsObligatoires() {
    this.groupeDeControle.addControl("selected", new UntypedFormControl('', Validators.required));
    this.groupeDeControle.addControl("role", new UntypedFormControl('', Validators.required));
  }

  private enleverControlsObligatoires() {
    this.groupeDeControle.removeControl("selected");
    this.groupeDeControle.removeControl("role");
  }

  modifierDateEcheanceDuRole(systemeRoleDateEcheance:string[]){//Il faut savoir le nom du système afin de pouvoir effacer le rôle
    var systeme:string = systemeRoleDateEcheance[0];
    var role:string = systemeRoleDateEcheance[1];
    var dateEcheance:string = systemeRoleDateEcheance[2];
    this.userResource.userName = this.userName;
    if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"]!=undefined && this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations!=undefined){
      for(var i:number=0; i<this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations.length; i++){
        if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].descriptionRole==role){
          if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].nomSysteme!=undefined){
            if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].nomSysteme==systeme){
              this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].dateEcheance=dateEcheance;
              break;              
            }
          }
        }
      }
    }
    this.modifierHabilitation(this.userResource, false);
  }

  retirerRoleHabilitation(systemeRole:string[]){//Il faut savoir le nom du système afin de pouvoir effacer le rôle
    var systeme:string = systemeRole[0];
    var role:string = systemeRole[1];
    this.userResource.userName = this.userName;
    if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"]!=undefined && this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations!=undefined){
      for(var i:number=0; i<this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations.length; i++){
        if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].descriptionRole==role){
          if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].nomSysteme!=undefined){
            if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].nomSysteme==systeme){
              this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations.splice(i, 1);
              i--;
            }
          }
        }
      }
    }
    this.modifierHabilitation(this.userResource, false);
  }

  
  retirerRoleFournisseur(role:string){//Il faut savoir le nom du système afin de pouvoir effacer le rôle
    this.userResource.userName = this.userName;
    var roles = this.userResource.roles;
    for (let i = 0; i < roles.length; i++) {
      var group = roles[i];
      if(group.value == role) {
        roles.splice(i, 1);
        break;
      }
    }
    this.modifierHabilitation(this.userResource, false);
  }

  retirerComposantHabilitation(roleComposant:string[]){ //Il faut savoir le nom du rôle afin de pouvoir effacer le composant   
    var role:string = roleComposant[0];
    var composant:string = roleComposant[1];
    this.userResource.userName = this.userName;
    if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"]!=undefined && this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations!=undefined){
      for(var i:number=0; i<this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations.length; i++){
        if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].descriptionRole==role){
          if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].descriptionComposant!=undefined){
            if(this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations[i].descriptionComposant==composant){
              this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations.splice(i, 1);
              i--;
            }
          }
        }
      }
    }
    this.modifierHabilitation(this.userResource, false);
  }

  modifierHabilitation(userResource: UserResource, ajouter:boolean){
    this.messageSucces = "";
    this.clientHTTPSX5.modifierHabilitationPUTHttp(this.userResource).subscribe(
      resultat => {    
        this.retournerAuTableau(this.userResource.userName, resultat);
      },
      (erreur: string) => {
                        ((ajouter==true) ? this.userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations.splice(-1,1) : "");
                        if(erreur.includes('HttpCode500__')){
                          var erreurs: string[] = [];
                          erreurs = erreur.split("__");
                          erreurs.push(this.userResource.userName);
                          this.rechargerPageAvecMessageErreur.emit(erreurs);
                        }else{
                          this.traitementErreurs.traitementErreur(erreur);
                        }
                      }
    );
  }

  getDescriptionComposant(nom:string):string{
    var descriptionComposant = "";
    for(var i:number=0; i<this.listeDesComposants.length; i++){
      if(this.listeDesComposants[i].nomComposant == nom){
        return descriptionComposant = this.listeDesComposants[i].descriptionComposant;
      }
    }
    return descriptionComposant;
  }

  getNomComposant(nom:string):string{
    var nomComposant = "";
    for(var i:number=0; i<this.listeDesComposants.length; i++){
      if(this.listeDesComposants[i].descriptionComposant == nom){
        return nomComposant = this.listeDesComposants[i].nomComposant;
      }
    }
    return nomComposant;
  }

  getDescriptionRole(nom:string):string{
    var descriptionRole = "";
    for(var i:number=0; i<this.listeDesRoles.length; i++){
      if(this.listeDesRoles[i].nomRole == nom){
        return descriptionRole = this.listeDesRoles[i].descriptionRole;
      }
    }
    return descriptionRole;
  }

  getNomRole(nom:string):string{
    var nomRole = "";
    for(var i:number=0; i<this.listeDesRoles.length; i++){
      if(this.listeDesRoles[i].descriptionRole == nom){
        return nomRole = this.listeDesRoles[i].nomRole;
      }
    }
    return nomRole;
  }

  retournerAuTableau(userName:string, resultat): Promise<boolean> {
    this.traitementErreurs.viderErreurs();
    this.messageSucces = "Modification du compte " + userName + " effectuée avec succès.";
    this.remplirDonnees(resultat);
    //this.valueChange.emit();
    return this.routeur.navigate(['/formulaire/'+userName]);
  }
}

export interface Groups {
  value: string;
  assigne: boolean;
}

export interface RoleSysteme {
  systeme: string;
  composant: string;
  role: string;
  assigne: boolean;
  dateEcheance?: string;
}

export interface Systeme{
  cleUnique?: string;
  nomSysteme: string;
  roles?: Roles [];
}

export interface Roles {
  nomRole: string;
  descriptionRole: string;
  composants: Composants[];
}

export interface Composants {
  nomComposant: string;
  descriptionComposant: string;
}