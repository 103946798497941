import { NgProgress } from 'ngx-progressbar';
import { HttpClient } from '@angular/common/http';

import { SessionCheckService } from './services/session-check.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { TraitementErreurService } from './services/traitement-erreurs/traitement-erreur.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ SessionCheckService ]
})
export class AppComponent {
  titre = 'Gestion des habilitations';

  today = Date.now();
  username = "";
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  public token = "";
  notification: string;
  showNotification: boolean;
  usagerConnecte: string;
  version: string;
  informationUtilisateurs: any;
  private continuerSession = true;
  theme = environment.theme;
  urlTheme = "";
  private _jsonURL = "../assets/img/theme/"+environment.theme+"/url.json"; 

  constructor(public progress: NgProgress,private http: HttpClient, private serviceKeycloak: KeycloakService,private sessionCheckService: SessionCheckService,
    public dialogue: MatDialog, public traitementErreurs: TraitementErreurService, private router: Router, private titleService: Title) {
    this.obtenirUsagerConnecte(serviceKeycloak);
    this.obtenirVersionApplication();
    this.lireUrlTheme();
  }  

  

  ngOnInit(): Promise<any>{
    this.setTitle(this.titre);

    this.sessionCheckService.validate()
      .subscribe((res) => {
        if(res === true && this.continuerSession === true){
          this.continuerSession = false;
          this.messageAvertissement();
        }
      });
    return;
  }

  public setTitle( newTitle: string) {    
    this.titre = newTitle;
    this.titleService.setTitle( newTitle );
  }

  public lireUrlTheme(){
    this.getJSON().subscribe(data => {
      this.urlTheme = data.url;
    }, (error) => {
      this.traitementErreurs.affecterDesErreurs(error);
    });
  }

  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  messageAvertissement() {
    this.traitementErreurs.viderErreurs();
    const dialogRef = this.dialogue.open(ExpirationSessionComponent,{
      data: {name: this.usagerConnecte, tempsExpirationSec: 30}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.actualiserSession();
        this.continuerSession = true;
      }
    });
  }

  actualiserSession() {
    this.serviceKeycloak.updateToken(300);//.then(e => console.log("updateToken: "+e));
  }

  deconnexion(): void {
    this.serviceKeycloak.logout(environment.angularProtectUrl);
  }

  obtenirVersionApplication() {
    this.http.get('assets/version.txt',{ responseType: 'text' }).subscribe(
      versionApplication => this.version = versionApplication,
      () => this.version = "la version n'a pas pu être obtenue"
    );
  }

  private obtenirUsagerConnecte(serviceKeycloak: KeycloakService) {
    this.serviceKeycloak.loadUserProfile().then(profil => {
      this.informationUtilisateurs = profil;
      this.usagerConnecte = this.affecterUsager(this.informationUtilisateurs);
    }, (erreur) => {
      this.traitementErreurs.affecterDesErreurs('Erreur à l\'obtention du profil de l\'usager = ' + erreur);
    });

  }

  public affecterUsager(informationUtilisateurs: KeycloakProfile): string {    
    let usagerConnecte = '';
    if (informationUtilisateurs.firstName === '' && informationUtilisateurs.username === '') {
      usagerConnecte = '(' + informationUtilisateurs.username + ')';
    } else if (informationUtilisateurs.firstName === '') {
      usagerConnecte = informationUtilisateurs.lastName.substring(0, 70) + ' (' + informationUtilisateurs.username + ')';
    } else if (informationUtilisateurs.lastName === '') {
      usagerConnecte = informationUtilisateurs.firstName.substring(0, 70) + ' (' + informationUtilisateurs.username + ')';
    } else {
      usagerConnecte = informationUtilisateurs.lastName + ', ' + 
                            informationUtilisateurs.firstName + ' (' + informationUtilisateurs.username + ')';
      if (usagerConnecte !== undefined && usagerConnecte.length > 100) {
          usagerConnecte = informationUtilisateurs.lastName.substring(0, 50) + ', ' +
                                informationUtilisateurs.firstName.substring(0, 50) +
                                ' (' + informationUtilisateurs.username + ')';
      }
    }

    return usagerConnecte;
  }
}

export interface DialogData {
    name: string;
    tempsExpirationSec: number;
}

@Component({
    selector: 'expiration-session.component',
    templateUrl: './expiration-session/expiration-session.component.html',
    styleUrls: ['./expiration-session/expiration-session.component.css']
  })
  export class ExpirationSessionComponent implements OnInit, OnDestroy {
  
    constructor(@Inject(MatDialogRef) public referenceDialogue: MatDialogRef<ExpirationSessionComponent>,
                public traitementErreurs: TraitementErreurService,
                private serviceKeycloak: KeycloakService,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {
                  referenceDialogue.disableClose = true;
                }

    private counter: Observable<number>;
    private subscription: Subscription;

    ngOnDestroy(): void {
        this.referenceDialogue.afterClosed().subscribe(result => {
            if(result !== undefined){
                this.subscription.unsubscribe();
            }
          });
    }
  
    ngOnInit() {
        
        const rt:any = this.serviceKeycloak.getKeycloakInstance().refreshTokenParsed;
        const dateFuture:number = rt.exp;
        this.counter = interval(1000).pipe(
          map((x) => {
            const dateActuelle = Math.floor(new Date().getTime() / 1000);
            return Math.floor(dateFuture - dateActuelle);
          }));
        this.subscription = this.counter.subscribe((res) => {
            this.data.tempsExpirationSec = res;
            if(res === 0){
                this.fermerSession();
            }
          });
        this.traitementErreurs.interrupteurDialogue();
    }

    fermerSession(): void {
        this.serviceKeycloak.logout(environment.angularProtectUrl);
        this.traitementErreurs.interrupteurDialogue();
        this.referenceDialogue.close();
    }
  }