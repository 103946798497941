
<section id="main">

    
  
    <div [ngClass]="{ 'messageErreur': erreurs.length != 0 }" >
        <li *ngFor="let erreur of erreurs">
            {{erreur}}
        </li>
    </div>
    
    <h3>Habilitation des utilisateurs</h3>
    
    <div [ngClass]="{ 'messageSucces': messageSuccesChangement.length != 0 }">
      <p>{{messageSuccesChangement}}</p>
    </div>

    <mat-expansion-panel expanded>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
          <b>Identifiant</b>
        </mat-label>
        <input autofocus matInput #identifiantHTML (keyup.enter)="chercherInformationsUtilisateur(identifiantHTML, prenomHTML, nomHTML, courrielHTML, systeme)">
      </mat-form-field>
      <div class="separateurEntreBouton"></div>
  
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
          <b>Prénom</b>
        </mat-label>
        <input matInput #prenomHTML (keyup.enter)="chercherInformationsUtilisateur(identifiantHTML, prenomHTML, nomHTML, courrielHTML, systeme)">
      </mat-form-field>
      <div class="separateurEntreBouton"></div>
  
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
          <b>Nom</b>
        </mat-label>
        <input matInput #nomHTML (keyup.enter)="chercherInformationsUtilisateur(identifiantHTML, prenomHTML, nomHTML, courrielHTML, systeme)">
      </mat-form-field>
      <div class="separateurEntreBouton"></div>
  
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
          <b>Courriel</b>
        </mat-label>
        <input matInput #courrielHTML (keyup.enter)="chercherInformationsUtilisateur(identifiantHTML, prenomHTML, nomHTML, courrielHTML, systeme)">
      </mat-form-field>
      <div class="separateurEntreBouton"></div>
      
      <mat-action-row>
        <button mat-raised-button class="boutonRecherche" (click)="chercherInformationsUtilisateur(identifiantHTML, prenomHTML, nomHTML, courrielHTML, systeme)">Rechercher</button>
        <div class="separateurEntreBoutonRecherche"></div>
        <button mat-raised-button class="boutonReinitialiser" (click)="reinitialiser(identifiantHTML, prenomHTML, nomHTML, courrielHTML, systeme)"> Réinitialiser </button>
      </mat-action-row>  
      <div class="separateurEntreBouton"></div>

      <div style="width: 100%; padding: 0; margin: 0;" *ngIf="alerteCritere">
        <span style="color: red;">Veuillez choisir au moins un critère pour pouvoir lancer la recherche</span>
      </div>

    </mat-expansion-panel>
    
    <button mat-raised-button class="boutonTable" [disabled]="!selection.hasValue()" (click)="allerAuFormulaireBouttonModifier()">Afficher</button>
  
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="sourceDeDonnees">
  
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>          
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="identifiant">
          <th mat-header-cell *matHeaderCellDef> Identifiant </th>
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{ 'selected': selection.isSelected(row)}"> {{element.identifiant}} </td>
          <mat-checkbox>Check me!</mat-checkbox>
        </ng-container>
  
        <ng-container matColumnDef="prenom">
          <th mat-header-cell *matHeaderCellDef> Prénom </th>
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{ 'selected': selection.isSelected(row)}"> {{element.prenom}} </td>
        </ng-container>
  
        <ng-container matColumnDef="nom">
          <th mat-header-cell *matHeaderCellDef> Nom </th>
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{ 'selected': selection.isSelected(row)}"> {{element.nom}} </td>
        </ng-container>
  
        <ng-container matColumnDef="courriel">
          <th mat-header-cell *matHeaderCellDef> Courriel </th>
          <td mat-cell *matCellDef="let row; let element" [ngClass]="{ 'selected': selection.isSelected(row)}"> {{element.courriel}} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'selected': selection.isSelected(row)}" (click)="selectionnerUtilisateur(row)" (dblclick)="allerAuFormulaireDoubleClique(row)"></tr>
      </table>
      
      <mat-paginator showFirstLastButtons [length]="nombreComptesTotal" [pageSize]='obtenirPageSize()' [pageSizeOptions]=[5,10,20] (page)="changementDePage($event, identifiantHTML, prenomHTML, nomHTML, courrielHTML, systeme)"></mat-paginator>
    </div>
  
    <button mat-raised-button class="boutonTable" [disabled]="!selection.hasValue()" (click)="allerAuFormulaireBouttonModifier()">Afficher</button>
  
  </section>