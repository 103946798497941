import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UtilisateurSCIM } from '../../shared/utils/user-scim';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { PaginationService } from '../services/pagination.service';
import { HttpClientSx5 } from '../services/http.client.sx5';
import { MessageService } from '../services/message.service';
import { Subscription } from 'rxjs';
import { Systeme } from '../group-roles/group-role-component';

const PREMIER_COURRIEL_DEFAUT = 0;
const VIDE = 0;
const MESSAGE_NON_RESULTATS = "La recherche n'a retourné aucun résultat.";
const MESSAGE_PLUS_CENT_RESULTATS="Votre recherche contient plus de 100 résultats. Si l'utilisateur recherché n'est pas dans la liste qui vous est présentée, veuillez raffiner vos critères de recherche.";

/**
 * @title Table with pagination
 */
@Component({
  selector: 'table-habilitation',
  styleUrls: ['table-habilitation.css'],
  templateUrl: 'table-habilitation.html',
})
export class TableHabilitation implements OnInit {

  reponse: String = "En attente d'un appel...";
  tableauDonnees: UtilisateurSCIM[] = [];
  displayedColumns = ['identifiant', 'prenom', 'nom', 'courriel'];
  sourceDeDonnees: MatTableDataSource<UtilisateurSCIM>;
  selection = new SelectionModel<UtilisateurSCIM>(false, null);
  identifiant: string = "";
  prenom: string = "";
  nom: string = "";
  courriel: string = "";
  systeme: string = "tousSystemes";

  messageSuccesChangement: string = "";
  erreurs: string[] = [];

  @Input()
  nombreComptesTotal: number = 0;

  @Output()
  page: EventEmitter<PageEvent>

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  alerteCritere: boolean = false

  requeteAuBackend: Subscription;

  constructor(public snackBar: MatSnackBar, private router: Router, public pagination: PaginationService, private clientHTTPSX5: HttpClientSx5, private message: MessageService) {
  }

  changementDePage(event: PageEvent, identifiantHtml, prenomHtml, nomHtml, courrielHtml, systemeHtml) {
    this.selection.clear();
    this.actualiserMessageAAfficher();
    this.pagination.changerNombreComptesParPage(event.pageSize);
    this.pagination.changerStartIndex((event.pageIndex * event.pageSize) + 1);
    this.pagination.changementIndexDePage(event.pageIndex);
    this.requeteAuBackend.unsubscribe();
    this.obtenirUtilisateurs(this.obtenirProchainStartIndex(event), event.pageSize, identifiantHtml.value, prenomHtml.value, nomHtml.value, courrielHtml.value, systemeHtml);
  }

  ngOnInit(): Promise<any> {    
    this.verifierAcces();
    return;
  }

  private verifierAcces(): void {
    this.clientHTTPSX5.obtenirListeCompteUtilisateurParFiltrePOSTHttp(0, 1, null, null, null, null, null)
      .subscribe(
        () => {},
        (erreur) => this.traitementErreurFormulaire(erreur)
      );
  }

  compareFn(c1: any, c2:any): boolean {
    return c1==c2;
  }

  obtenirPageSize(): number {
    return this.pagination.obtenirNombreComptesParPage();
  }

  obtenirUtilisateurs(startIndex: number, pageSize: number, identifiant: string, prenom: string, nom: string, courriel: string, systeme: string) {
    this.requeteAuBackend = this.clientHTTPSX5.obtenirListeCompteUtilisateurParFiltrePOSTHttp(startIndex, pageSize, identifiant, prenom, nom, courriel, systeme)
      .subscribe(
        result => {
          this.traitementDuReponse(result);
        },
        (erreur) => this.traitementErreurFormulaire(erreur)
      );
  }

  traitementDuReponse(result) {
    if (this.resultatsObtenusNonValide(result)) {
      this.remplirTableau(result);
      this.nombreComptesTotal = result.totalResults;
      if (result.Resources.length == 0) {
        this.traitementErreurFormulaire(MESSAGE_NON_RESULTATS);
      }else if(this.nombreComptesTotal == 100){
        this.traitementErreurFormulaire(MESSAGE_PLUS_CENT_RESULTATS);
      }
    }
  }

  remplirTableau(result) {
    this.tableauDonnees.length = VIDE;
    for (let i = 0; i < result.Resources.length; i++) {
      this.tableauDonnees.push({
        identifiant: result.Resources[i].userName,
        prenom: result.Resources[i].name.givenName,
        nom: result.Resources[i].name.familyName,
        courriel: result.Resources[i].emails[PREMIER_COURRIEL_DEFAUT].value
      });
    }
    this.sourceDeDonnees = new MatTableDataSource<UtilisateurSCIM>(this.tableauDonnees);
    this.erreurs = [];
  }

  validationValeurs(parametre: string) {
    return (parametre != undefined && parametre != "") ? true : false;
  }

  obtenirProchainStartIndex(event: PageEvent) {
    return (event.pageIndex * event.pageSize) + 1;
  }

  resultatsObtenusNonValide(result) {
    return result != null && result != undefined && result.Resources != undefined;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  selectionnerUtilisateur(row) {
    if (this.selection.isSelected(row)) {
      this.selection.clear();
    }
    else {
      this.selection.select(row);
    }
  }

  allerAuFormulaireBouttonModifier(): Promise<boolean> {
    return this.router.navigate(['/formulaire', this.selection.selected[0].identifiant]);
  }

  allerAuFormulaireDoubleClique(row): Promise<boolean> {
    if (this.selection.isEmpty()) {
      this.selectionnerUtilisateur(row);
    }
    return this.router.navigate(['/formulaire', this.selection.selected[0].identifiant]);
  }

  private actualiserMessageAAfficher() {
    if (this.message.message, length != 0) {
      this.messageSuccesChangement = this.message.message;
      this.message.viderMessage();
    }
    else {
      this.messageSuccesChangement = this.message.message;
    }
  }

  traitementErreurFormulaire(erreur: string) {
    if (this.erreurs.length != 0) {
      this.erreurs = [];
    }
    if (erreur.indexOf!=undefined && erreur.indexOf('|') > -1) {
      this.erreurs = erreur.split("|");
    }
    else {
      this.erreurs.push(erreur);
    }
  }

  chercherInformationsUtilisateur(identifiant, prenom, nom, courriel, systeme) {
    this.alerteCritere = false;
    if (identifiant.value || prenom.value || nom.value || courriel.value) {
      this.paginator.pageIndex = VIDE;
      this.selection.clear();
      this.obtenirUtilisateurs(1, this.pagination.obtenirNombreComptesParPage(), identifiant.value, prenom.value, nom.value, courriel.value, systeme);
    } else {
      this.alerteCritere = true;
    }
  }

  reinitialiser(identifiantHTML, prenomHTML, nomHTML, courrielHTML, systeme) {
    identifiantHTML.value = "";
    prenomHTML.value = "";
    nomHTML.value = "";
    courrielHTML.value = "";
    this.systeme = "";
    this.alerteCritere = false;
  }
}
