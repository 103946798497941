import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { configLog } from './app/utils/gestion-logs';

if (environment.production) {
	enableProdMode();
	configLog(environment.logLevel);
}


platformBrowserDynamic().bootstrapModule(AppModule);