import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InterdictionComponent } from './errors/interdiction/interdiction.component';
import { TableHabilitation } from './liste-paginee/table-habilitation';
import { ErrorsComponent } from './errors/errors-component/errors.component';
import { FormulaireAffichage } from './formulaireAffichage/formulaireAffichage';
import { ErrorsHandler } from './errors/errors-handler/errors-handler';
import { PageNonTrouveeComponent } from './errors/not-found/page-non-trouvee.component';
import { AppAuthGuard } from './app.authguard';
import { APP_BASE_HREF } from '@angular/common';


const routes: Routes = [
    { path: '',
    redirectTo: 'tableHabilitation',
    pathMatch: 'full' },
    {
        path: 'tableHabilitation', 
        component: TableHabilitation,
        canActivate: [AppAuthGuard]
    },
    {
        path: 'formulaire/:identifiant',
        component: FormulaireAffichage,
        canActivate: [AppAuthGuard]
    },
    { path: 'interdiction', component: InterdictionComponent },
    { path: 'error/:id', component: ErrorsComponent},
    { path: '**', component: PageNonTrouveeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }]
})
export class AppRoutingModule {}