<div class="container">
  <mat-expansion-panel expanded class="col-sm-12">
    <div class="row">
        <h3></h3>
        <nav class="nav flex-sm-column nav-pills col-sm-3" style="padding-top: 10px;">
          <a data-bs-toggle="tab" data-bs-target="#bb" class="nav-link active">Habilitations</a>
          <a data-bs-toggle="tab" data-bs-target="#aa" class="nav-link">Rôles au niveau du fournisseur de services</a>
          <a data-bs-toggle="tab" data-bs-target="#cc" class="nav-link">Groupes au niveau du fournisseur de services</a>
        </nav>
        <div class="tab-content col-sm-9">
          <div class="tab-pane active" id="bb">
            <div *ngIf="listeDeSystemes.length>0">
              <div [ngClass]="{ 'messageErreur': this.traitementErreurs.nombreErreurs() != 0 }">
                <li *ngFor="let erreur of this.traitementErreurs.obtenirListeErreurs()">
                  {{erreur}}
                </li>
              </div>
              <div [ngClass]="{ 'messageSucces': messageSucces.length != 0 }">
                <p>{{messageSucces}}</p>
              </div>

              <h4><b>Ajouter une nouvelle habilitation</b></h4>

              <mat-form-field appearance="outline" class="largeSelect form-input-block">
                <mat-label>Système</mat-label>
                <mat-select placeholder="Système" #systemeHtml [formControl]="this.groupeDeControle.controls.selected"
                  required [compareWith]="compareFn" (keyup.enter)="remplirListeDeComposantsEtRolesDeSystemeFiltre()"
                  (click)="remplirListeDeComposantsEtRolesDeSystemeFiltre()"
                  (blur)="remplirListeDeComposantsEtRolesDeSystemeFiltre()"
                  (change)="remplirListeDeComposantsEtRolesDeSystemeFiltre()">
                  <mat-option *ngFor="let systeme of listeDeSystemes" [value]="systeme.nomSysteme">
                    {{ systeme.nomSysteme }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="largeSelect form-input-block">
                <mat-label>Rôle</mat-label>
                <mat-select placeholder="Rôle" #roleHtml [formControl]="this.groupeDeControle.controls.role" required
                  name="role" (keyup.enter)="remplirListeDeComposantsXRoleFiltre()"
                  (click)="remplirListeDeComposantsXRoleFiltre()" (blur)="remplirListeDeComposantsXRoleFiltre()">
                  <mat-option *ngFor="let role of listeDesRoles" [value]="role.descriptionRole">
                    {{ role.descriptionRole }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="largeSelect form-input-block">
                <mat-label>Composant</mat-label>
                <mat-select placeholder="Composant" #composantHtml
                  [formControl]="this.groupeDeControle.controls.composant" name="composant">
                  <mat-option [value]="tousComposants">Tous les composants</mat-option>
                  <mat-option *ngFor="let composant of listeDesComposants" [value]="composant.descriptionComposant">
                    {{ composant.descriptionComposant }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="dateEcheanceCss" >
                <mat-label>Date d'échéance</mat-label>
                <input [formControl]="this.groupeDeControle.controls.dateEcheance" #dateEcheance matInput
                  [matDatepicker]="picker1" placeholder="Date d'échéance" [min]="dateMin" maxlength="10">
                <mat-hint align="start">AAAA-MM-JJ</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="this.groupeDeControle.controls.dateEcheance.invalid">
                  {{getErrorMessage(dateEcheance.value)}}</mat-error>
              </mat-form-field>

              <div class="separateurEntreSelects"></div>
              <div class="separateurEntreSelects"></div>

              <button id="retour" *ngIf="this.groupeDeControle.status == 'VALID'" mat-raised-button color="primary"
                (click)="ajouterHabilitation(dateEcheance.value)">
                <mat-icon>add</mat-icon>Ajouter
              </button>
              <button id="retour" *ngIf="this.groupeDeControle.status == 'INVALID'" mat-raised-button color="primary"
                disabled>
                <mat-icon>add</mat-icon>Ajouter
              </button>
              
              <arbre-habilitation (effacerRole)='retirerRoleHabilitation($event)'
                (effacerComposant)='retirerComposantHabilitation($event)'
                (dateEcheanceDuRole)='modifierDateEcheanceDuRole($event)'>loading</arbre-habilitation>
            </div>
            <div *ngIf="!voirLesHabilitations">
              <div class="alert alert-info">
                Vous n'êtes pas habilité à voir les habilitations de cet utilisateur
              </div>
            </div>


          </div>

          <div class="tab-pane" id="aa">
            <div [ngClass]="{ 'messageErreur': this.traitementErreurs.nombreErreurs() != 0 }">
              <li *ngFor="let erreur of this.traitementErreurs.obtenirListeErreurs()">
                {{erreur}}
              </li>
            </div>
            <div [ngClass]="{ 'messageSucces': messageSucces.length != 0 }">
              <p>{{messageSucces}}</p>
            </div>
            <mat-list #roles>
              <mat-list-item *ngFor="let role of listeDeRoles">
                <mat-label>{{role.value}}</mat-label>
                <button *ngIf="role.assigne" mat-icon-button color="warn" (click)="retirerRoleFournisseur(role.value)" matTooltip="Retirer le rôles" matTooltipPosition="right" class="bouton-effacer"><mat-icon>delete</mat-icon></button>
              </mat-list-item>
            </mat-list>
          </div>

          <div class="tab-pane" id="cc">
            <mat-list #groups>
              <mat-list-item *ngFor="let group of listeDeGroupes">
                {{group.value}}
              </mat-list-item>
            </mat-list>
          </div>
        </div>
        <!-- /tabs -->
    </div>
  </mat-expansion-panel>
</div>