import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ErrorsHandler implements ErrorHandler {

  constructor(private injector: Injector) 
  {
  }

  handleError(error: any): Promise<boolean> {
      const router = this.injector.get(Router);
      if (error instanceof HttpErrorResponse) {
      // Server error happened      
        if (!navigator.onLine) {
          // No Internet connection
          return router.navigate(['/error', "-1"]);;
        }
        // Http Error
        return router.navigate(['/error', error.status]);
      } else {
        // Client Error Happend    
        return router.navigate(['/error', error.message]);
      }
  }
}