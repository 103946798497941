import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Injectable } from "@angular/core";

@Injectable()
export class MatPaginatorFrancais extends MatPaginatorIntl {
    itemsPerPageLabel = "Items par page"
    firstPageLabel = "Première page";
    lastPageLabel = "Dernière page";
    nextPageLabel = "Page suivante";
    previousPageLabel = "Page précédente"
    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length == 0 || pageSize == 0) { return ``;//`0 de ${length}`;
        } 
        length = Math.max(length, 0);
        const startIndex = page * pageSize;const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize; return `${startIndex + 1} - ${endIndex} de ${length}`; }
}