import { Injectable} from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
 
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserResource, Entitlements, HabilitationsEntity, Schemas } from '../group-roles/userResource';
import { Router } from '@angular/router';

const URL_ENDPOINT_SCIM = "/scim/v2/Users/";
const LIEN_LISTE_COMPTES_UTILISATEURS = ".search";
const LIEN_LISTE_SYSTEMES = "/vocabulaire/systemes";
const RESTE_PIN = '/$reset-pin/';
const CORPS_VIDE = '';

@Injectable()
export class HttpClientSx5 {
    
    constructor(private httpClient: HttpClient, private router: Router) 
    {
    }

    obtenirListeSystemesGETHttp(): Observable<any> {
        var url: string = this.obtenirSystemesURLEndpoint();
        return this.httpClient.get(url).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    obtenirListeCompteUtilisateurPOSTHttp(startIndex:number, count:number): Observable<any> {
        var url: string = this.obtenirURLEndpoint()+LIEN_LISTE_COMPTES_UTILISATEURS;
        var paramsString: string;
        paramsString = '{"schemas": ["urn:ietf:params:scim:api:messages:2.0:SearchRequest"],"startIndex": '+startIndex+',"count":'+count+'}';
        return this.httpClient.post(url, JSON.parse(paramsString)).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    obtenirListeCompteUtilisateurParFiltrePOSTHttp(startIndex:number, count:number, identifiant:string, prenom:string, nom:string, courriel:string, systeme:string): Observable<any> {
        var url: string = this.obtenirURLEndpoint()+LIEN_LISTE_COMPTES_UTILISATEURS;
        var paramsString: string;
        var filtre: string = this.obtenirFiltre(identifiant, prenom, nom, courriel, systeme);
        paramsString = '{"schemas": ["urn:ietf:params:scim:api:messages:2.0:SearchRequest"],"startIndex": '+startIndex+',"count":'+count+',"filter":"'+filtre+'"}';
        return this.httpClient.post(url, JSON.parse(paramsString)).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    private obtenirFiltre(identifiant:string, prenom:string, nom:string, courriel:string, systeme:string): string{
        var filtre:string="";
        if(this.valeurValide(identifiant)){
            filtre = 'username sw \\"'+this.anticorruptionRecherche(identifiant) + '\\"';
        }
        if(this.valeurValide(prenom)){           
            filtre = filtre + (filtre.length > 0 ? ' and ' : '') + 'firstName sw \\"' + this.anticorruptionRecherche(prenom) + '\\"';
        }
        if(this.valeurValide(nom)){
            filtre = filtre + (filtre.length > 0 ? ' and ' : '') + 'lastName sw \\"' + this.anticorruptionRecherche(nom) + '\\"';
        }
        if(this.valeurValide(courriel)){
            filtre = filtre + (filtre.length > 0 ? ' and ' : '') + 'emails[value sw \\"' + this.anticorruptionRecherche(courriel) + '\\"]';
        }
        if(this.valeurValide(systeme) && systeme!="tousSystemes"){
            filtre = filtre + (filtre.length > 0 ? ' and ' : '') + 'groups[display eq \\"' + this.anticorruptionRecherche(systeme) + '\\"]';
        }
        return filtre;
    }

    obtenirUtilisateurParIdentifiantGETHttp(identifiant:string): Observable<any> {
        var url: string = this.obtenirURLEndpoint() + identifiant;
        var parametres= new HttpParams;
        parametres.set('identifiant', identifiant);
        return this.httpClient.get(url, { params: parametres}).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    modifierHabilitationPUTHttp(userResource:UserResource): Observable<any> {
        var url: string = this.obtenirURLEndpoint() + userResource.userName;
        var parametres= new HttpParams;
        parametres.set('identifiant', userResource.userName);
        var corpsJSON: string = this.obtenirCorpsJSONDunUtilisateur(userResource);
        return this.httpClient.put(url, JSON.parse(corpsJSON),{ params: parametres, headers: this.obtenirEnTeteSCIM()}).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    reinitialiserPinPOSTHttp(identifiant: string, uid: string) {
        const url: string = this.obtenirURLEndpoint() + identifiant + RESTE_PIN + uid;
        return this.httpClient.post(url, CORPS_VIDE).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    private obtenirCorpsJSONDunUtilisateur(userResource:UserResource): string {
        var corpJson:string="";
        var id:string = this.anticorruption(userResource.id);
        var metaResourceType = this.anticorruption(userResource.meta.resourceType);
        var metaCreated = this.anticorruption(userResource.meta.created);
        var metaLastModified = this.anticorruption(userResource.meta.lastModified);
        var metaLocation = this.anticorruption(userResource.meta.location);
        var metaVersion = this.anticorruption(userResource.meta.version);
        var userName = this.anticorruption(userResource.userName);
        var nameFormatted = this.anticorruption(userResource.name.formatted);
        var nameFamilyName = this.anticorruption(userResource.name.familyName);
        var nameGivenName = this.anticorruption(userResource.name.givenName);
        var nameMiddleName = this.anticorruption(userResource.name.middleName);
        var nameHonorificPrefix = this.anticorruption(userResource.name.honorificPrefix);
        var nameHonorificSuffix = this.anticorruption(userResource.name.honorificSuffix);
        var email = userResource.emails && userResource.emails.length > 0 ? this.anticorruption(userResource.emails[0].value) : "";

        corpJson = '{'
                    +'"schemas": ['+this.getSchema(userResource.schemas)+'],'
                    +'"id": '+id+','
                    +'"meta": {'
                    +           '"resourceType": '+metaResourceType+','
                    +           '"created": '+metaCreated+','
                    +           '"lastModified": '+metaLastModified+','
                    +           '"location": '+metaLocation+','
                    +           '"version": '+metaVersion+''
                    +        '},'
                    +'"userName": '+ userName + ','
                    +'"name": { '
                    +           '"formatted": '  + nameFormatted + ',' 
                    +           '"familyName": ' + nameFamilyName + ','
                    +           '"givenName": ' + nameGivenName + ','
                    +           '"middleName": ' + nameMiddleName + ','
                    +           '"honorificPrefix": ' + nameHonorificPrefix + ','
                    +           '"honorificSuffix": ' + nameHonorificSuffix + ''
                    +         '},' 
                    +'"emails": ['
                    +             '{' 
                    +                 '"value": '+ email + ''
                    +             '}'
                    +          '],';
                    if(userResource.roles && userResource.roles.length > 0) {
                        corpJson += '"roles": '
                        +             JSON.stringify(userResource.roles)
                        +          ','
                    }
                    
                    corpJson += '"urn:sx5:scim:api:messages:2.0:Habilitations":{'
                    +'   "habilitations": '
                    +          '['
                    +             this.getEntitlements(
                                    userResource["urn:sx5:scim:api:messages:2.0:Habilitations"] ?
                                    userResource["urn:sx5:scim:api:messages:2.0:Habilitations"].habilitations :
                                    []
                                )
                    +          ']}'
                    + '}';
        return corpJson;
    }

    private getEntitlements(habilitationsEntity:HabilitationsEntity[]):string{
        var entitlementsChaine:string="";
        for(var i:number=0; i<habilitationsEntity.length; i++){
            if(i==0){
                entitlementsChaine='{"nomRole": '+ this.anticorruption(habilitationsEntity[i].nomRole) + ','
                +                   '"nomSysteme": '+ this.anticorruption(habilitationsEntity[i].nomSysteme) + ','
                +                   '"nomComposant": '+ this.anticorruption(habilitationsEntity[i].nomComposant) + ''
                +                   this.getDateEcheance(habilitationsEntity[i]);
            }else{
                entitlementsChaine+=',{"nomRole": '+ this.anticorruption(habilitationsEntity[i].nomRole) + ','
                +                   '"nomSysteme": '+ this.anticorruption(habilitationsEntity[i].nomSysteme) + ','
                +                   '"nomComposant": '+ this.anticorruption(habilitationsEntity[i].nomComposant) + ''
                +                   this.getDateEcheance(habilitationsEntity[i]) + '';
            }
        }
        return entitlementsChaine;
    }

    private getDateEcheance(habilitationsEntity:HabilitationsEntity):string{
        var dateEcheance:string = "}";
        if(habilitationsEntity.dateEcheance!=undefined && habilitationsEntity.dateEcheance!=null){
            dateEcheance = "," + '"dateEcheance": "'+ habilitationsEntity.dateEcheance + '"}';
        }
        return dateEcheance;
    }

    private getSchema(schema:string[]):string{
        var schemaRetour:string="";
        for(var i:number=0; i<schema.length; i++){
            if(i==0){
                schemaRetour='"'+schema[i]+'"'
            }else{
                schemaRetour+=',"'+schema[i]+'"'
            }
        }
        return schemaRetour;
    }

    private obtenirEnTeteSCIM(): HttpHeaders {
        return new HttpHeaders({ 'Content-Type': 'application/scim+json'});
    }

    traitementDErreur(error: HttpErrorResponse){
        if(error.status == 0) {
            return throwError("Le serveur est présentement non disponible, veuillez réessayer plus tard.");
        }
        else if (error.status == 401) {
            return throwError("Vous êtes déconnecté.");
        }
        else if(error.status == 403){
            if(error.error?.status == 403 && error.error?.detail!=undefined && error.error?.detail!=""){
                return throwError(error.error?.detail);
            }else{            
                return this.router.navigate(['/interdiction']);
            }
        }
        else if(error.status == 405) {
            return throwError("L'utilisateur que vous essayez d'accéder n'a pas encore d'identifiant");
        }
        else if(error.status == 500) {
            if(error.error.schemas!=undefined && error.error.schemas[0]=="urn:ietf:params:scim:api:messages:2.0:Error"){
                return throwError("HttpCode500__" + error.error.detail);
            }else{
                return throwError("Un problème est survenu. Veuillez contacter votre administrateur réseau.");
            }            
        }else if(error.error.detail!=undefined){
            return throwError(error.error.detail);
        }else{
            if(error.statusText == "Unknown Error") {
                return throwError("Une erreur est survenue. Si le problème persiste, veuillez contactez votre administrateur de réseau.");
            }else{
                return throwError(error.message);
            }            
        }
    }

    public obtenirURLEndpoint(): string{
        return environment.sx5ServiceUrl+URL_ENDPOINT_SCIM;
    }

    public obtenirSystemesURLEndpoint(): string{
        return environment.sx5ServiceUrl+LIEN_LISTE_SYSTEMES;
    }

    valeurValide(parametre:string):boolean{
        if(parametre!=undefined && parametre!=""){
            return true;
        }
        return false;
    }

    anticorruptionRecherche(parametre: string): string {
        if(parametre==null){
            return null;
        }
        if(parametre.includes("\"")){
            var re = /\"/gi; 
            var parametreNetoye = parametre.replace(re, "\\\"");
            return parametreNetoye;
        }
        return parametre;
    }

    anticorruption(parametre: string): string{
        if(parametre==null || parametre==undefined){
            return null;
        }
        if(parametre.includes("\"")){
            var re = /\"/gi; 
            var parametreNetoye = parametre.replace(re, "\\\"");
            return parametreNetoye;
        }
        return '"'+parametre+'"';
    }
}