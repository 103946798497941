import { Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateFormat } from '../role-arbre/arbre-habilitation.component';
import { FormControl } from '@angular/forms';

@Pipe({
    name: 'dateFormat'
  })
  export class DateFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any { 
       return super.transform(value, "yyyy-MM-dd");
    }

    getDateValide(date:any){
      var dateFormat: DateFormat;
        dateFormat = date as DateFormat;
        if(dateFormat!=undefined && dateFormat!=null){
          var day:number = parseInt(dateFormat.date);
          var month = parseInt(dateFormat.month);
          var year = parseInt(dateFormat.year);
          var dateAuComplet = new Date(year, month, day);
          var datee = this.transform(dateAuComplet);
          return datee;
        }
    }
  }