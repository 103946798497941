import {interval as observableInterval,  Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

@Injectable()
export class SessionCheckService {
    constructor( private keycloakAngular: KeycloakService ){};

    validate(): Observable<any> {
        return observableInterval(environment.intervalleVerificationSession).pipe(map( (x) => 
                {
                    var rt:any = this.keycloakAngular.getKeycloakInstance().refreshTokenParsed;
                    var dateExpirationSession:number = rt.exp;
                    var dateActuelle = Math.floor(new Date().getTime() / 1000);
                    if((dateActuelle + 30) == dateExpirationSession){
                        return true;
                    }else{
                        return false;
                    }
                }
        ));
    }
}